<template>
    <div class="display-card py-3 px-5">
        <div class="dF aC" style="justify-content: space-between">
            <div class="dF aC">
                <h5 style="margin-bottom: 0">Unit {{ unit.unitNumber }}</h5>
                <div class="ml-3" :style="styles">{{ statusLabel }}</div>
            </div>
            <a-tooltip
				overlayClassName="change-tooltip-color"
                placement="topRight"
                :title="
                    unit.transactionId && unit.salesStatus === 'sold'
                        ? 'This Unit has been SOLD. A mutual release is required to Void the sale.'
                        : 'This Unit is locked as its currently in a Transaction. Please check the Transaction App'
                "
                v-if="unit.transactionId && unit.salesStatus !== 'available'"
            >
                <a-button :disabled="true" shape="circle" icon="lock" />
            </a-tooltip>
            <a-button
                v-else
                shape="circle"
                @click="$emit('updateStatus')"
                icon="edit"
            />
        </div>
        <div class="grid-display-4 gap-5" :key="$store.state.inventory.newTime">
            <div>
                <div class="mt-3">
                    <div style="color: #9ea0a5">Bathrooms</div>
                    <div>{{ unit.packages[0].baths }}</div>
                </div>
                <div class="mt-3">
                    <div style="color: #9ea0a5">Price per Sqft</div>
                    <div>{{ pricePerSqft }}</div>
                </div>
                <div class="mt-3">
                    <div style="color: #9ea0a5">Storage</div>
                    <div>{{ unit.packages[0].locker > 0 ? "Yes" : "No" }}</div>
                </div>
            </div>
            <div>
                <div>
                    <div style="color: #9ea0a5">Price</div>
                    <div>{{ `$${unit.packages[0].price}` }}</div>
                </div>
                <div class="mt-3">
                    <div style="color: #9ea0a5">Bedrooms</div>
                    <div>{{ unit.packages[0].beds }}</div>
                </div>
                <div class="mt-3">
                    <div style="color: #9ea0a5">Parking Lots</div>
                    <div>{{ `$${unit.packages[0].parking}` }}</div>
                </div>
            </div>
            <div>
                <div>
                    <div style="color: #9ea0a5">Area</div>
                    <div>{{ unit.packages[0].sqft }}</div>
                </div>
                <div class="mt-3">
                    <div style="color: #9ea0a5">Floor / Level</div>
                    <div>{{ unit.unitGroup.order }}</div>
                </div>
            </div>
            <div>
                <div>
                    <div style="color: #9ea0a5">Exposure</div>
                    <div>{{ exposure }}</div>
                </div>
                <div class="mt-3">
                    <div style="color: #9ea0a5">Balcony</div>
                    <div>{{ unit.packages[0].beds }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["id"],
    data: () => ({
        exposureLookup: {
            north: "North",
            northwest: "North West",
            west: "West",
            southwest: "South West",
            south: "South",
            southeast: "South East",
            east: "East",
            northeast: "North East",
        },
    }),
    watch: {},
    computed: {
        exposure() {
            let { exposure } = this.unit.packages[0];
            if (!exposure) return "N/A";
            exposure = exposure.toLowerCase();
            return this.exposureLookup[exposure];
        },
        pricePerSqft() {
            return (
                "$" +
                (
                    this.unit.packages[0].price / this.unit.packages[0].sqft
                ).toFixed(2)
            );
        },
        unit() {
            return this.$store.state.inventory.units[this.id] || {};
        },
        styles() {
            return this.unit.salesStatus == "available"
                ? "color:#1EC48C"
                : this.unit.salesStatus == "sold"
                ? "background-color:#FD6C83;color:white; padding:0 2px"
                : this.unit.salesStatus == "hold"
                ? "background-color:#FECE5B; padding: 0 2px;"
                : "color:#9693E8";
        },
        statusLabel() {
            return (
                this.unit.salesStatus.charAt(0).toUpperCase() +
                this.unit.salesStatus.slice(1)
            );
        },
    },
};
</script>

<style>
</style>
