<template>
    <div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar">
        <FilterDrawer />
        <a-modal
            :visible="modal.visible"
            @cancel="onClose"
            :centered="true"
            :footer="null"
        >
            <h5>{{ `Unit ${modal.object.unitNumber}` }}</h5>
            <a-form-model
                v-if="modal.type == 'updateStatus'"
                ref="status"
                :model="status"
                class="mt-5"
            >
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item
                            label="Status"
                            required
                            :rules="req('Please select one')"
                            prop="status"
                        >
                            <a-select v-model="status.status">
                                <a-select-option
                                    v-for="stat in statusOptions"
                                    :key="stat.value"
                                    :value="stat.value"
                                    >{{ stat.label }}</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                        <a-col
                            :span="24"
                            v-if="
                                status.status == 'sold' ||
                                status.status == 'conditional'
                            "
                        >
                            <a-form-model-item label="Final Price" prop="price">
                                <a-input-number
                                    style="width: 100%"
                                    type="number"
                                    v-model="status.price"
                                    :formatter="
                                        (value) =>
                                            `$ ${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            )
                                    "
                                    :parser="
                                        (value) =>
                                            value.replace(/\$\s?|(,*)/g, '')
                                    "
                                ></a-input-number>
                            </a-form-model-item>
                        </a-col>
                    </a-col>
                </a-row>
            </a-form-model>
            <a-form-model
                v-else-if="modal.type == 'makeTransaction'"
                ref="transaction"
                :model="transaction"
                class="mt-5"
            >
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item
                            label="Status"
                            required
                            :rules="req('Please select one')"
                            prop="status"
                        >
                            <a-select v-model="transaction.status">
                                <a-select-option
                                    v-for="stat in statusOptions"
                                    :key="stat.value"
                                    :value="stat.value"
                                    >{{ stat.label }}</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item
                            label="Final Price"
                            required
                            :rules="req('Please fill in the price')"
                            prop="price"
                        >
                            <a-input
                                v-model="transaction.price"
                                type="number"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <div style="width: 100%" class="dF jE">
                <div class="dF aC">
                    <a-button
                        :style="{ marginRight: '8px' }"
                        @click="onClose"
                        style="
                            border: none !important;
                            background-color: #ece9f1;
                            width: 150px;
                        "
                        >CANCEL</a-button
                    >
                    <a-button
                        @click="submit"
                        type="primary"
                        style="width: 150px"
                        >UPDATE</a-button
                    >
                </div>
            </div>
        </a-modal>
        <a-tabs
            @change="changeTab"
            default-active-key="1"
            size="large"
            style="width: 100%; height: 100vh; overflow-y: scroll"
            class="tab-height hide-scrollbar"
        >
            <a-tab-pane
                key="1"
                tab="All Inventory"
                style="width: 100%; height: 100%"
            >
                <div v-if="Object.values(units).length == 0">
                    <div
                        class="dF aC mb-5"
                        style="justify-content: space-between"
                    >
                        <div class="dF aC">
                            <a-icon
                                @click="backButton"
                                class="mr-4"
                                style="font-size: 1.75rem; cursor: pointer"
                                type="arrow-left"
                            />
                            <h5 style="margin-bottom: 0">
                                {{ unitGroups[floor].name }}
                            </h5>
                            <div style="color: #9ea0a5" class="ml-3">
                                {{
                                    Object.values(units).length <
                                        Object.values(storedUnits).length &&
                                    (filter.price.length != 0 ||
                                        filter.beds[0] != "any" ||
                                        filter.baths != "any" ||
                                        filter.exposure[0] != "any" ||
                                        filter.status[0] != "any")
                                        ? `${
                                              Object.values(units).length
                                          } results match your search`
                                        : `${
                                              Object.values(units).length
                                          } in total`
                                }}
                            </div>
                        </div>
                        <div class="dF aC">
                            <a-select
                                :value="floor"
                                @change="changeUnitGroup"
                                style="width: 150px"
                            >
                                <a-select-option
                                    v-for="uG in unitGroups"
                                    :key="uG.id"
                                    :value="uG.id"
                                    >{{ uG.name }}</a-select-option
                                >
                            </a-select>

                            <a-button
                                class="ml-3"
                                v-if="isFiltered"
                                @click="clearFilter"
                                ghost
                                type="primary"
                                >Clear Filter <a-icon type="delete"
                            /></a-button>
                            <div
                                @click="openFilter"
                                class="dF aC px-3 py-1 ml-3"
                                style="
                                    border-radius: 3px;
                                    background-color: #fff;
                                    cursor: pointer;
                                    border: 1px solid #ecebed;
                                "
                            >
                                <div>Filters</div>
                                <div class="ml-3" style="height: 24px">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-filter"
                                    >
                                        <polygon
                                            points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"
                                        ></polygon>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <EmptyApp
                        :text="'No units found'"
                        :img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'"
                    />
                </div>
                <div v-else>
                    <div class="dF aC" style="justify-content: space-between">
                        <div class="dF aC">
                            <a-icon
                                @click="backButton"
                                class="mr-4"
                                style="font-size: 1.75rem; cursor: pointer"
                                type="arrow-left"
                            />
                            <h5 style="margin-bottom: 0">
                                {{ unitGroups[floor].name }}
                            </h5>
                            <div style="color: #9ea0a5" class="ml-3">
                                {{
                                    Object.values(units).length <
                                        Object.values(storedUnits).length &&
                                    (filter.price.length != 0 ||
                                        filter.beds[0] != "any" ||
                                        filter.baths != "any" ||
                                        filter.exposure[0] != "any" ||
                                        filter.status[0] != "any")
                                        ? `${
                                              Object.values(units).length
                                          } results match your search`
                                        : `${
                                              Object.values(units).length
                                          } in total`
                                }}
                            </div>
                        </div>
                        <div class="dF aC">
                            <a-select
                                :value="floor"
                                @change="changeUnitGroup"
                                style="width: 150px"
                            >
                                <a-select-option
                                    v-for="uG in unitGroups"
                                    :key="uG.id"
                                    :value="uG.id"
                                    >{{ uG.name }}</a-select-option
                                >
                            </a-select>
                            <a-button
                                class="ml-3"
                                v-if="isFiltered"
                                @click="clearFilter"
                                ghost
                                type="primary"
                                >Clear Filter <a-icon type="delete"
                            /></a-button>
                            <div
                                @click="openFilter"
                                class="dF aC px-3 py-1 ml-3"
                                style="
                                    border-radius: 3px;
                                    background-color: #fff;
                                    cursor: pointer;
                                    border: 1px solid #ecebed;
                                "
                            >
                                <div>Filters</div>
                                <div class="ml-3" style="height: 24px">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-filter"
                                    >
                                        <polygon
                                            points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"
                                        ></polygon>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a-row :gutter="[16, 16]" class="mt-5">
                        <a-col
                            :span="24"
                            v-for="unit in order(units)"
                            :key="unit.id"
                        >
                            <CondoUnitsCard
                                title="hey"
                                @updateStatus="
                                    openModal(unit.id, 'updateStatus')
                                "
                                :id="unit.id"
                                :key="$store.state.inventory.newTime"
                            />
                        </a-col>
                    </a-row>
                </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Price Management">
                <HighrisePrice />
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import EmptyApp from "bh-mod/components/common/EmptyApp";
import FilterDrawer from "@/components/inventory/FilterDrawer";
import HighrisePrice from "@/components/inventory/HighrisePrice";
import CondoUnitsCard from "@/components/inventory/CondoUnitsCard";
export default {
    components: {
        EmptyApp,
        FilterDrawer,
        HighrisePrice,
        CondoUnitsCard,
    },
    data() {
        return {
            jumpToFloor: "",
            modal: {
                visible: false,
                type: "",
                object: {},
            },
            status: {
                status: "",
                price: 0,
            },
            transaction: {
                status: "",
                price: 0,
            },
            statusOptions: [
                { value: "available", label: "Available" },
                { value: "hold", label: "Hold" },
                { value: "sold", label: "Sold" },
                { value: "conditional", label: "Conditional" },
                { value: "inventory", label: "Inventory" },
            ],
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler(val) {
                if (val.params && val.params.id && val.params.id != "") {
                    this.$store.commit("SET_PROP", {
                        where: ["selectedFloor"],
                        what: val.params.id,
                    });
                }
            },
        },
    },
    computed: {
        isFiltered() {
            return this.$store.getters.isHighriseFiltered;
        },
        filter() {
            return this.$store.state.inventory.highriseFilter;
        },
        storedUnits() {
            let units = {};
            if (this.$store.state.instance.productType == "highrise") {
                Object.values(this.$store.state.inventory.units).forEach(
                    (unit) => {
                        if (unit.buildingType == "condounits") {
                            units[unit.id] = unit;
                        }
                    }
                );
            }
            return units;
        },
        floor() {
            return this.$store.state.inventory.selectedFloor;
        },
        units() {
            const orderAlpha = (array) => {
                array = array.sort((a, b) => {
                    if (parseInt(a.name) < parseInt(b.name)) return -1;
                    if (parseInt(a.name) > parseInt(b.name)) return 1;
                    else return 0;
                });
                return array;
            };

            let filter = this.$store.state.inventory.highriseFilter;
            let result = this.$store.getters.floorUnits;

            result = result.filter((unit) => {
                let pkg = unit.packages[0];

                let {
                    price = 0,
                    beds = 0,
                    baths = 0,
                    hasDen = false,
                    exposure,
                } = pkg;

                if (filter.price.length) {
                    let minPrice = filter.price[0];
                    let maxPrice = filter.price[1];
                    if (price < minPrice || price > maxPrice) return false;
                }

                if (filter.beds[0] != "any") {
                    let isPassed = [];
                    filter.beds.forEach((bed) => {
                        let needsDen = bed.includes("+");
                        bed = +bed.split("+")[0];

                        let currentMark = beds === bed;
                        if (needsDen && currentMark) {
                            currentMark = hasDen;
                        }
                        isPassed.push(currentMark);
                    });
                    if (!isPassed.includes(true)) return false;
                }

                if (filter.baths != "any") {
                    if (baths != filter.baths) return false;
                }

                if (filter.status[0] != "any") {
                    if (!filter.status.includes(unit.salesStatus)) return false;
                }

                if (filter.exposure[0] != "any") {
                    if (!filter.exposure.includes(exposure)) return false;
                }

                return true;
            });

            return orderAlpha(result);
        },
        instance() {
            return this.$store.state.instance;
        },
        unitGroups() {
            return this.$store.state.inventory.unitGroups;
        },
    },
    methods: {
        changeTab(e) {
            console.log("e", e);
        },
        order(object) {
            let obj = {};
            Object.values(object).forEach((x) => {
                if (
                    this.instance.productType == "highrise" &&
                    x.buildingType == "condounits"
                ) {
                    obj[x.id] = x;
                }
            });
            return obj;
        },
        clearFilter() {
            let filter = {
                assignee: ["any"],
				type: ["any"],
				price: [0, 0],
				sqft: [0, 0],
				beds: ["any"],
				baths: "any",
				floor: "any",
				exposure: ["any"],
				exterior: ["any"],
				tags: ["any"],
            };
            this.$store.commit("UPDATE_HIGHRISE_FILTER", filter);
        },
        backButton() {
            this.$router.push("/");
            let crumbs = JSON.parse(
                JSON.stringify(this.$store.state.inventory.crumbs)
            );
            // crumbs.splice(1,1)
            this.$store.commit("UPDATE_CRUMBS", crumbs);
        },
        req: (msg) => ({ required: true, message: msg }),
        changeUnitGroup(e) {
            this.$router.push(`/details/${e}`);
        },
        openModal(id, type) {
            let unit = this.$store.state.inventory.units[id];

            this.modal.visible = true;
            this.modal.object = unit;
            this.modal.type = type;
            if (type == "updateStatus") {
                this.status.status = unit.salesStatus;
            } else if (type == "makeTransaction") {
                this.transaction.status = unit.salesStatus;
            }
        },
        onClose() {
            if (this.modal.type == "updateStatus")
                this.$refs.status.resetFields();
            if (this.modal.type == "makeTransaction")
                this.$refs.transaction.resetFields();
            this.modal.visible = false;
            this.modal.type = "";
            this.modal.object = {};
        },
        submit() {
            if (this.modal.type == "updateStatus") {
                let object = {};
                if (
                    this.status.status == "sold" ||
                    this.status.status == "conditional"
                ) {
                    object = {
                        unit: this.modal.object.id,
                        packageId: this.modal.object.packages[0].id,
                        finalPrice: this.status.price,
                        status: this.status.status,
                    };
                    if (typeof this.modal.object.unitGroup == "string")
                        object.unitGroup = this.modal.object.unitGroup;
                    else if (typeof this.modal.object.unitGroup == "object")
                        object.unitGroup = this.modal.object.unitGroup.id;
                } else if (this.status.status == "available") {
                    object = {
                        unit: this.modal.object.id,
                        status: this.status.status,
                    };
                } else if (this.status.status == "inventory") {
                    object = {
                        unit: this.modal.object.id,
                        status: this.status.status,
                        packageId: this.modal.object.packages[0].id,
                    };
                    if (typeof this.modal.object.unitGroup == "string")
                        object.unitGroup = this.modal.object.unitGroup;
                    else if (typeof this.modal.object.unitGroup == "object")
                        object.unitGroup = this.modal.object.unitGroup.id;
                } else if (this.status.status == "hold") {
                    object = {
                        unit: this.modal.object.id,
                        status: this.status.status,
                    };
                }
                this.$api
                    .put(`/sales/:instance/hr`, object)
                    .then(({ data }) => {
                        this.$store.commit("SET_PROP", {
                            where: ["units", data.id],
                            what: data,
                        });
                        this.onClose();
                    })
                    .catch((err) => {
                        this.$message.error(
                            "An error occurred. Please try again"
                        );
                    })
                    .finally(() => {
                        this.$store.commit("LOAD", false);
                    });
            } else if (this.modal.type == "makeTransaction") {
                console.log("transaction", this.transaction);
            }
        },
        openFilter() {
            this.$store.commit("OPEN_FILTER");
        },
    },
};
</script>

<style scoped>
.gap-5 {
    gap: 1.25rem;
}
.popover-content {
    padding: 3px;
}
.popover-content:hover {
    background-color: var(--off-white-dark);
}
</style>
<style>
.no-padding .ant-popover-inner-content {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    width: 170px;
}
</style>
